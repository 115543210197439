<template>
<b-card no-body>
  <validation-observer ref="VForm">
    <b-form @submit.prevent="doSubmitData">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Nama Template<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Nama Template" type="text" class="form-control" v-model="row.mtp_name"/>
                  <VValidate 
                    name="Nama Template" 
                    v-model="row.mtp_name" 
                    :rules="toValidate(mrValidation.mtp_name)"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Izinkan Template untuk digunakan oleh semua user<span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    @change="changeDokter($event)"
                    :options="Config.mr.statusTemplatePenunjang"
                    v-model="row['mtp_is_general']"
                  />
                  <VValidate 
                    name="Izinkan Template untuk digunakan oleh semua user" 
                    v-model="row.mtp_is_general" 
                    :rules="toValidate(mrValidation.mtp_is_general)"
                  />
                </b-form-group>
                <b-form-group v-if="row.mtp_is_general != 'Y'">
                  <label>Dokter<span class="text-danger mr5">*</span></label>
                  <v-select :disabled="$parent.isDokter" placeholder="Pilih Dokter" v-model="row.mtp_dokter"
                  :options="mDokter" label="text" :clearable="true"
                  :reduce="v=>v.value"></v-select>
                  <VValidate 
                    name="Dokter" 
                    v-model="row.mtp_dokter" 
                    :rules="{required: 1}"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <label>Item Pemeriksaan Penunjang Lab</label>
            <div class="card-body p-1">
              <div class="req-list-resume">
                <div class="row g-3">
                  <template v-for="(v,k) in row.mtp_lab_temp">
                      <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                      <div class="card shadow-0 border">
                          <div class="card-header py-2 bg-light">
                          <h6 class="card-title text-uppercase font-weight-semibold">
                              {{v.head||"-"}}</h6>
                          </div>
                          <div class="card-body py-2">
                          <template v-for="v1,k1 in (v.dubData||[])">
                              <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)"
                              :key="k1+'labdatas'">
                              <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                              <template v-for="v2,k2 in (v1.data||[])">
                                  <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                                  <i
                                      class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                  <b class="align-middle font-weight-semibold">{{v2.text||"-"}}</b>
                                  <!--
                                  <span class="" v-if="v2.notes">, {{v2.notes}}</span>
                                  -->
                                  </div>
                              </template>
                              </div>
                          </template>
                          </div>
                      </div>
                      </div>
                  </template>
                          
                  <div class="col-md-4 col-lg-3" v-if="row.mtp_lab_temp_lainnya">
                      <div class="card shadow-0 border">
                      <div class="card-header py-2 bg-light">
                          <h6 class="card-title text-uppercase font-weight-semibold">Item
                          Pemeriksaan Lainnya</h6>
                      </div>
                      <div class="card-body py-2">
                          <div class="req-list">
                          <div>
                              <i
                              class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <b class="align-middle font-weight-semibold">{{row.mtp_lab_temp_lainnya||"-"}}</b>
                          </div>
                          </div>
                      </div>
                      </div>
                  </div>
                </div>
                          
                <div class="text-center mt-3">
                  <a href="javascript:;" @click="doOpenLab" class="btn alpha-blue mr-1"><i class="icon-plus2 mr-2"></i>Tambah Pemeriksaan
                    Laboratorium</a>
                </div>

              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <label>Item Pemeriksaan Penunjang Radiologi</label>
            <div class="card-body p-1">
              <div class="req-list-resume">
                <div class="row g-3">
                <template v-for="(v,k) in row.mtp_radio_temp">
                    <div class="col-md-4 col-lg-3" :key="k+'radioData'"
                    v-if="isShowHeadRadiologi(k)">
                    <div class="card shadow-0 border">
                        <div class="card-header py-2 bg-light">
                        <h6 class="card-title text-uppercase font-weight-semibold">{{
                getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                        </div>
                        <div class="card-body py-2">
                        <template v-for="v1,k1 in (v.data||[])">
                            <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                            <i
                                class="icon-checkmark-circle text-success align-middle mr-1"></i>
                            <b class="align-middle font-weight-semibold">{{v1.text||"-"}}</b>
                            <!--
                            <span class="" v-if="v1.notes">, {{v1.notes}}</span>
                            -->
                            </div>
                        </template>
                        </div>
                    </div>
                    </div>
                </template>
                <div class="col-md-4 col-lg-3" v-if="row.mtp_radio_temp_lainnya">
                    <div class="card shadow-0 border">
                    <div class="card-header py-2 bg-light">
                        <h6 class="card-title text-uppercase font-weight-semibold">Item
                        Pemeriksaan Lainnya</h6>
                    </div>
                    <div class="card-body py-2">
                        <div class="req-list">
                        <div>
                            <i
                            class="icon-checkmark-circle text-success align-middle mr-1"></i>
                            <b class="align-middle font-weight-semibold">{{row.mtp_radio_temp_lainnya||"-"}}</b>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
              </div>
              <div class="text-center mt-3">
                <a href="javascript:;" @click="doOpenRadiologi" class="btn alpha-blue"><i class="icon-plus2 mr-2"></i>Tambah Pemeriksaan
                  Radiologi</a>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-form-group class="mt-3">
              <label>Status <span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="Config.mr.StatusOptions"
                v-model="row['mtp_is_active']"
              />

              <VValidate 
                name="Status" 
                v-model="row.mtp_is_active" 
                :rules="toValidate(mrValidation.mtp_is_active)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>

  <b-modal v-model="openLab" :title="'Pilih Item Pemeriksaan Laboratorium'" size="xl" hide-footer>
      <div class="modal-header d-block p-0 mt-3">
          <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
          <li v-for="(v,k) in row.mtp_lab_temp" :key="k" @click="changeTabLab(k)" class="nav-item">
              <a href="javascript:;" data-toggle="tab"
              :class="k == activeTabLab ? 'nav-link active' : 'nav-link'">{{v.head||"-"}}</a>
          </li>
          </ul>
      </div>
      <div class="modal-body p-0 mt-3">
          <div class="tab-content">
          <div v-for="(v,k) in row.mtp_lab_temp" :key="k+'labform'"
              :class="k == activeTabLab ? 'tab-pane fade show active' : 'tab-pane fade'">
              <template v-for="(v1,k1) in (v.dubData||[])">
              <div :key="k1+'sss'">
                  <div v-if="v1.sub" class="selection-control font-weight-semibold bg-light text-uppercase">
                  {{v1.sub}}</div>
                  <div class="row sc-row g-0">
                  <template v-for="(v2,k2) in (v1.data||[])">
                      <div class="col-md-4 col-lg-3" v-if="v2.id" :key="k2+'xdx'">
                          <div class="selection-control">
                          <b-form-checkbox :name="'tabLab'+String(v2.id)" v-model="v2['selected']">{{v2.text||"-"}}
                          </b-form-checkbox>
                          <!--
                          <b-form-input type="text" v-if="v2['selected']" v-model="v2['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                          -->
                          </div>
                      </div>
                  </template>
                  </div>
              </div>
              </template>
          </div>
          </div>
          <div class="row ml-1 mt-2">
          <div class="col-md-8 col-lg-4">
              <div class="form-group row">
              <label>Lainnya</label>
              <b-form-input v-model="row.mtp_lab_temp_lainnya" type="text" class="form-control" />
              </div>
          </div>
          </div>
      </div>
      <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
          <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
          <span class="m-0" v-if="selectedLabInput">{{selectedLabInput||"-"}}</span>
          <span v-else> - </span>
      </div>
      <div class="modal-footer p-0 mt-3 bg-white">
          <div class="text-right">
          <button @click="openLab = false" data-dismiss="modal" class="btn">Selesai</button>
          </div>
      </div>
  </b-modal>


  <b-modal v-model="openRadiologi" :title="'Pilih Item Pemeriksaan Radiologi'" size="xl" hide-footer>
    <div class="modal-header d-block p-0 mt-3">
        <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
        <li v-for="(v,k) in row.mtp_radio_temp" :key="k" @click="changeTabRadiologi(k)" class="nav-item">
            <a href="javascript:;" data-toggle="tab"
            :class="k == activeTabRadiologi ? 'nav-link active' : 'nav-link'">{{getConfigDynamic(Config.mr.KategoriRadiologi, v.head)||"-"}}</a>
        </li>
        </ul>
    </div>

    <div class="modal-body p-0 mt-3">
        <div class="tab-content">
        <div v-for="(v,k) in row.mtp_radio_temp" :key="k+'radform'"
            :class="k == activeTabRadiologi ? 'tab-pane fade show active' : 'tab-pane fade'">
            <div class="row sc-row g-0">
            <div class="col-md-3" v-for="(v1,k1) in (v.data||[])" :key="k1+'radio'">
                <div class="selection-control">
                <b-form-checkbox @input="changeInpRadiologi($event,v1,k,k1)" :name="'tabrad'+String(v1.id)" v-model="v1['selected']">{{v1.text||"-"}}
                </b-form-checkbox>
                <!--
                <b-form-input type="text" v-if="v1['selected']" v-model="v1['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                -->
                </div>
            </div>
            </div>
        </div>
        </div>
        <div class="row ml-1 mt-2">
        <div class="col-md-4">
            <div class="form-group row">
            <label>Lainnya</label>
            <b-form-input v-model="row.mtp_radio_temp_lainnya" type="text" class="form-control" />
            </div>
        </div>
        </div>
    </div>

    <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
        <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
        <span class="m-0" v-if="selectedRadioInput">{{selectedRadioInput}}</span>
        <span v-else> - </span>
    </div>
    <div class="modal-footer p-0 mt-3 bg-white">
        <div class="text-right">
        <button @click="openRadiologi = false" data-dismiss="modal" class="btn">Selesai</button>
        </div>
    </div>
  </b-modal>
  

</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
  },
  computed :{
    mDokter(){
      return this.$parent.mDokter
    },
    selectedLabInput() {
      let data = []
      for (let ik = 0; ik < (this.row.mtp_lab_temp || []).length; ik++) {
        for (let jk = 0; jk < (this.row.mtp_lab_temp[ik]['dubData'] || []).length; jk++) {
            for (let kk = 0; kk < (this.row.mtp_lab_temp[ik]['dubData'][jk]['data'] || []).length; kk++) {
                if (this.row.mtp_lab_temp[ik]['dubData'][jk]['data'][kk]['selected']) {
                    data.push(this.row.mtp_lab_temp[ik]['dubData'][jk]['data'][kk]['text'])
                }
            }
        }
      }
      if (this.row.mtp_lab_temp_lainnya) {
          data.push(this.row.mtp_lab_temp_lainnya)
      }
      return data.join(", ")
    },
    selectedRadioInput() {
      let data = []
      for (let i = 0; i < (this.row.mtp_radio_temp || []).length; i++) {
        for (let j = 0; j < (this.row.mtp_radio_temp[i]['data'] || []).length; j++) {
            if (this.row.mtp_radio_temp[i]['data'][j]['selected']) {
                data.push(this.row.mtp_radio_temp[i]['data'][j]['text'])
            }
        }
      }
      if (this.row.mtp_radio_temp_lainnya) {
        data.push(this.row.mtp_radio_temp_lainnya)
      }
      return data.join(", ")
    },

    selectedLabInputID() {
      let data = []
      for (let ik = 0; ik < (this.row.mtp_lab_temp || []).length; ik++) {
        for (let jk = 0; jk < (this.row.mtp_lab_temp[ik]['dubData'] || []).length; jk++) {
            for (let kk = 0; kk < (this.row.mtp_lab_temp[ik]['dubData'][jk]['data'] || []).length; kk++) {
                if (this.row.mtp_lab_temp[ik]['dubData'][jk]['data'][kk]['selected']) {
                    data.push(this.row.mtp_lab_temp[ik]['dubData'][jk]['data'][kk]['id'])
                }
            }
        }
      }
      if (this.row.mtp_lab_temp_lainnya) {
          data.push(99999)
      }
      return data
    },
    selectedRadioInputID() {
      let data = []
      for (let i = 0; i < (this.row.mtp_radio_temp || []).length; i++) {
        for (let j = 0; j < (this.row.mtp_radio_temp[i]['data'] || []).length; j++) {
            if (this.row.mtp_radio_temp[i]['data'][j]['selected']) {
                data.push(this.row.mtp_radio_temp[i]['data'][j]['id'])
            }
        }
      }
      if (this.row.mtp_radio_temp_lainnya) {
        data.push(99999)
      }
      return data
    }
  },
  data(){
    return {
      openLab: false,
      activeTabLab: 0,
      openRadiologi: false,
      activeTabRadiologi: 0,

      // configResep : [
      //   {
      //     text : "Obat",
      //     value : 1
      //   },
      //   {
      //     text : "Alat Kesehatan",
      //     value : 2
      //   },
      //   {
      //     text : "Infus",
      //     value : 5
      //   },
      // ]
    }
  },
  methods: {
    toValidate(val){
      return {...val}
    },

    // lab
    isShowHeadLab(i) {
      let isData = 0
      for (let j = 0; j < (this.row.mtp_lab_temp[i]['dubData'] || []).length; j++) {
          for (let k = 0; k < (this.row.mtp_lab_temp[i]['dubData'][j]['data'] || []).length; k++) {
              if (this.row.mtp_lab_temp[i]['dubData'][j]['data'][k]['selected']) {
                  isData += 1
              }
          }
      }
      return isData
    },
    isShowHeadSubHeadLab(i, j) {
      let isData = 0
      for (let k = 0; k < (this.row.mtp_lab_temp[i]['dubData'][j]['data'] || []).length; k++) {
          if (this.row.mtp_lab_temp[i]['dubData'][j]['data'][k]['selected']) {
              isData += 1
          }
      }
      return isData
    },
    isShowLab(i, j, k) {
      let isData = 0
      if (this.row.mtp_lab_temp[i]['dubData'][j]['data'][k]['selected']) {
          isData += 1
      }
      return isData
    },
    doOpenLab() {
      this.openLab = true
    },
    changeTabLab(e) {
      this.activeTabLab = e
    },
    // lab

    // radiologi
    
    isShowHeadRadiologi(j) {
      let isData = 0
      for (let k = 0; k < (this.row.mtp_radio_temp[j]['data'] || []).length; k++) {
        if (this.row.mtp_radio_temp[j]['data'][k]['selected']) {
            isData += 1
        }
      }
      return isData
    },    
    isShowRadiologi(j, k) {
        let isData = 0
        if (this.row.mtp_radio_temp[j]['data'][k]['selected']) {
            isData += 1
        }
        return isData
    },
    doOpenRadiologi() {
      this.openRadiologi = true
    },
    changeTabRadiologi(e) {
      this.activeTabRadiologi = e
    },
    // radiologi

    doSubmitData(){
      if(!this.selectedLabInput && !this.selectedRadioInput){
        return this.$swal({
          icon: 'error',
          title: 'Minimal isi 1 data Permintaan',
        })
      }

      if(this.selectedLabInput){
        this.row.mtp_is_lab = "Y"
      } 
      if(this.selectedRadioInput){
        this.row.mtp_is_radio = "Y"
      }

      this.row.mtp_lab_selected_id = this.selectedLabInputID
      this.row.mtp_radio_selected_id = this.selectedRadioInputID

      this.handleSubmit(this.$parent.doSubmitCRUD('VForm', this.$refs))
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },
    changeDokter(e){
      if(!e){
        this.row.mtp_dokter = null
      }else{
        if(this.$parent.isDokter){
          this.row.mtp_dokter = this.$parent.user.id
        }
      }
    }
  },
}
</script>